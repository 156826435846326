import axios from 'axios'
import { 
    USER_DETAILS_FAIL, 
    USER_DETAILS_REQUEST, 
    USER_DETAILS_SUCCESS, 
    USER_LOGIN_FAIL, 
    USER_LOGIN_REQUEST, 
    USER_LOGIN_SUCCESS, 
    USER_LOGOUT, 
    USER_REGISTER_FAIL, 
    USER_REGISTER_REQUEST, 
    USER_REGISTER_SUCCESS, 
    USER_UPDATE_PROFILE_FAIL, 
    USER_UPDATE_PROFILE_REQUEST, 
    USER_UPDATE_PROFILE_SUCCESS, 
    USER_DETAILS_RESET, 
    USER_LIST_REQUEST, 
    USER_LIST_SUCCESS, 
    USER_LIST_FAIL, 
    USER_LIST_RESET, 
    USER_DELETE_REQUEST, 
    USER_DELETE_SUCCESS, 
    USER_DELETE_FAIL, 
    USER_UPDATE_REQUEST, 
    USER_UPDATE_SUCCESS, 
    USER_UPDATE_FAIL, 
    USER_VERIFICATION_REQUEST, 
    USER_VERIFICATION_SUCCESS, 
    USER_VERIFICATION_FAIL, 
    USER_GENERATE_OTP_REQUEST, 
    USER_GENERATE_OTP_SUCCESS, 
    USER_GENERATE_OTP_FAIL,
    USER_GENERATE_OTP_RESET} from "../constants/userConstants"

// export const login = (email, password) => async (dispatch) => {
//     try{
//         dispatch({
//             type: USER_LOGIN_REQUEST
//         })

//         //have to use this whenever there is a use of headers in this case header authorization
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         }
 
//         const {data} = await axios.post('/api/users/login', {email, password}, config)
    
//         dispatch({
//             type: USER_LOGIN_SUCCESS,
//             payload: data
//         })

//         localStorage.setItem('userInfo', JSON.stringify(data))
//     } catch (error){
//         dispatch({
//             type: USER_LOGIN_FAIL, 
//             payload: error.response && error.response.data.message ? error.response.data.message : error.message,
//         })
//     }
// }

export const login = (email, otp) => async(dispatch) => {
    try{
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        //have to use this whenever there is a use of headers in this case header authorization
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
 
        const {data} = await axios.post('/api/users/login', {email, otp}, config)
    
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        }) 

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error){
        dispatch({
            type: USER_LOGIN_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const generateOTP = (email) => async(dispatch) => {
    try{
        dispatch({
            type: USER_GENERATE_OTP_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        await axios.post('/api/users/generateOTPForLogin', {email}, config)

        dispatch({
            type: USER_GENERATE_OTP_SUCCESS,
        })
    } catch(error){
        dispatch({
            type: USER_GENERATE_OTP_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const logout = () => async (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({
        type: USER_LOGOUT
    })
    dispatch({
        type: USER_GENERATE_OTP_RESET
    })
    dispatch({
        type: USER_DETAILS_RESET
    })
    dispatch({
        type: USER_LIST_RESET
    })
}

export const register = (
    name, 
    email, 
    collegeEmail, 
    rollNumber, 
    phone, 
    resume, 
    cgpa, 
    tenthPercentage, 
    twelfthPercentage, 
    department, 
    programme, 
    lookingFor,
    dateOfBirth) => async (dispatch) => {
    try{
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        //have to use this whenever there is a use of headers in this case header authorization
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
 
        const {data} = await axios.post('/api/users', {
            name, 
            email, 
            collegeEmail, 
            rollNumber, 
            phone, 
            resume, 
            cgpa, 
            tenthPercentage, 
            twelfthPercentage, 
            department, 
            programme, 
            lookingFor,
            dateOfBirth
        }, config)
    
        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })

        // dispatch({
        //     type: USER_LOGIN_SUCCESS,
        //     payload: data
        // })

        // localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error){
        dispatch({
            type: USER_REGISTER_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const verify = (userId, otpForEmail, otpForCollegeEmail, otpForPhone) => async(dispatch) => {
    try {
        dispatch({
            type: USER_VERIFICATION_REQUEST
        })


        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const {data} = await axios.post(`/api/users/verify`, {userId, otpForEmail, otpForCollegeEmail, otpForPhone}, config)
        
        dispatch({
            type: USER_VERIFICATION_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: USER_VERIFICATION_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const getUserDetails = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: USER_DETAILS_REQUEST
        })

        const {userLogin: {userInfo}} = getState()

        //have to use this whenever there is a use of headers in this case header authorization
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
 
        const {data} = await axios.get(`/api/users/${id}`, config)
    
        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error){
        dispatch({
            type: USER_DETAILS_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const updateUserProfile = (user) => async (dispatch, getState) => {
    try{
        dispatch({
            type: USER_UPDATE_PROFILE_REQUEST
        })

        const {userLogin: {userInfo}} = getState()

        //have to use this whenever there is a use of headers in this case header authorization
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
 
        const {data} = await axios.put(`/api/users/profile`, user, config)
    
        dispatch({
            type: USER_UPDATE_PROFILE_SUCCESS,
        })
        
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error){
        dispatch({
            type: USER_UPDATE_PROFILE_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const listUsers = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: USER_LIST_REQUEST
        })

        const {userLogin: {userInfo}} = getState()

        //have to use this whenever there is a use of headers in this case header authorization
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
 
        const {data} = await axios.get(`/api/users`, config)
    
        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data
        })
    } catch (error){
        dispatch({
            type: USER_LIST_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const deleteUser = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: USER_DELETE_REQUEST
        })

        const {userLogin: {userInfo}} = getState()

        //have to use this whenever there is a use of headers in this case header authorization
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
 
        await axios.delete(`/api/users/${id}`, config)
    
        dispatch({
            type: USER_DELETE_SUCCESS
        })
    } catch (error){
        dispatch({
            type: USER_DELETE_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const updateUser = (user) => async (dispatch, getState) => {
    try{
        dispatch({
            type: USER_UPDATE_REQUEST
        })

        const {userLogin: {userInfo}} = getState()

        //have to use this whenever there is a use of headers in this case header authorization
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
 
        const {data} = await axios.put(`/api/users/${user._id}`, user, config)
    
        dispatch({
            type: USER_UPDATE_SUCCESS
        })

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error){
        dispatch({
            type: USER_UPDATE_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}